import React, { useState, useEffect } from "react"
import "./assets/styles/_index.scss"

import PropertyDetailNego from "../PropertyDetailNego/PropertyDetailNego"

const FloatingButton = (props) => {
  // Sticky scroll
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 350)
    })
    if (scroll) {
      document.body.classList.add("chat-with-button-scroll")
    } else {
      document.body.classList.remove("chat-with-button-scroll")
    }
  }, [scroll])
  // Sticky scroll

  return (
    <div className="d-xl-none">
      {scroll && (
        <section className={`floating-button-wrapper ${props.tag}`}>
          <PropertyDetailNego
            property={props?.property}
            mobiletog={props?.mobiletog}
            setMobileTog={props?.setMobileTog}
            mobview
          />
        </section>
      )}
    </div>
  )
}

export default FloatingButton
