import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import sitelogoimage from "../images/logo.svg"

// import { PageLinks } from "../common/site/page-static-links";
import FloatingButton from "../components/FloatingButton/FloatingButton"
import { PageLinks } from "../common/site/page-static-links"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const PropertyDetailBanner = loadable(() =>
  import("../components/PropertyDetailBanner/PropertyDetailBanner")
)
const PropertyDetailDesc = loadable(() =>
  import("../components/PropertyDetailDesc/PropertyDetailDesc")
)
const FeaturedProperties = loadable(() =>
  import("../components/FeaturedProperties/SimilarProperties")
)
// const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const PropertyDetails = ({ data, children }) => {
  const [mobiletog, setMobileTog] = useState(false)

  const [negoEmail, setNegoEmail] = useState("")
  const [loader, setLoader] = useState(true)
 
  const property = data?.allProperties?.nodes?.length > 0 ? data?.allProperties?.nodes[0] : []
  useEffect(()=> {
    setLoader(false)
  }, [property])
  // // const companyphone = data?.site?.siteMetadata?.mailVars?.company_phone
  // const officeDetailGlobal = data?.strapiOfficeDetail
  const page_url = typeof window !== "undefined" ? window.location.href : ""

  // const soldArray = ["Sold"]
  // let isSoldProperty = soldArray.includes(property?.status)

  let processedImages = JSON.stringify({})
  if (property.imagetransforms?.images_Transforms) {
    processedImages = property?.imagetransforms?.images_Transforms
  }

  const propertyDetailImg = []
  for (let i = 0; i < property?.images?.length; i++) {
    if (property?.images[i].url) {
      propertyDetailImg.push(property?.images[i].url)
    }
  }

  // // epc lightbox
  // const propertyEpcImg = []
  // for (let i = 0; i < property?.epc?.strapi_json_value.length; i++) {
  //   if (property?.epc?.strapi_json_value[i].url) {
  //     propertyEpcImg.push(property?.epc?.strapi_json_value[i].url)
  //   }
  // }

  // // floorplan lightbox
  // const propertyFloorplanImg = []
  // for (let i = 0; i < property?.floorplan?.strapi_json_value.length; i++) {
  //   if (property?.floorplan?.strapi_json_value[i].url) {
  //     propertyFloorplanImg.push(property?.floorplan?.strapi_json_value[i].url)
  //   }
  // }

  // let popularSearchDetailsObject = {
  //     // department: props.page_url_data.departmentVal,
  //     searchType: property.search_type,
  //     // action: property.search_type === "sales" ? "for-sale" : "to-rent",
  //     area: property.area,
  //     postcode: property.address.postcode,
  //     // bedrooms: props.page_url_data.bedVal,
  //     // propertyType: props.page_url_data.buildingval,
  //     // minprice: props.page_url_data.minpriceVal,
  //     // maxprice: props.page_url_data.maxpriceVal,
  // }

  return (
    loader ? <div className="loader"><div className="after-loader"></div></div>:
    <Layout
      customClass={mobiletog ? "footer-cta mob-hide" : "footer-cta"}
      popularSearch="residential-detail"
    >
    
   
      <div className="layout-padding-top" />

      <BreadcrumbModule
        parentname="Holiday lets"
        parent={PageLinks.rent}
        // subparentlabel={"Property to rent"}
        // subparentlink={PageLinks.results_lettings}
        pagename={property.display_address}
      />

      <PropertyDetailBanner
        title={property.display_address}
        images={property?.images}
        processedImages={processedImages}
        strapi_id={property?.strapi_id}
        crm_id={property?.crm_id}
        propsImg={propertyDetailImg}
        // propertyEpcImg={propertyEpcImg}
        // propertyFloorplanImg={propertyFloorplanImg}
        // videoTour={property?.video_tour}
        ggfx_results={property?.ggfx_results}
      />

      <PropertyDetailDesc
        property={property}
        companyphone={
          property?.crm_negotiator_id?.mobile_phone
            ? property?.crm_negotiator_id?.mobile_phone
            : property?.crm_negotiator_id?.work_phone
        }
        propImg={propertyDetailImg}
        page_url={page_url}
        setNegoEmail={setNegoEmail}
        ggfx_results={property?.ggfx_results}
        strapi_id={property?.strapi_id}
        processedImages={processedImages}
        // isSoldProperty={isSoldProperty}
      />

      <FeaturedProperties
        prop_id={property?.strapi_id}
        tag="property-details"
      />

      <FloatingButton
        tag="property-details"
        property={property}
        mobiletog={mobiletog}
        setMobileTog={setMobileTog}
        // phone={property?.crm_negotiator_id?.mobile_phone ? property?.crm_negotiator_id?.mobile_phone : property?.crm_negotiator_id?.work_phone}
        // type={property.search_type}
        // crm_id={property.crm_id}
        // address={property.display_address}
        // page_url={page_url}
        // propImage={propertyDetailImg && propertyDetailImg.length > 0 ? propertyDetailImg[0] : ''}
        // nego_email={negoEmail}
        // isSoldProperty={isSoldProperty}
      />
     
    </Layout>
  )
}

export const query = graphql`
  query ($strapi_id: Int) {
    allProperties(filter: { strapi_id: { eq: $strapi_id } }) {
    nodes {
      ...PropertyFragment
    }
  }
  }
`

export const Head = (props) => {
  // seo title, h1 and desc prepare, this can send to utilis function later
  const desc_meta_ptype =
    props.data.strapiProperty?.building?.length > 0
      ? props.data.strapiProperty?.building?.join(" and ")
      : "property"

  const cap_ptype =
    desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1)

  let desc_meta_seachtype = "for sale"
  if (props.data.strapiProperty?.search_type == "lettings") {
    desc_meta_seachtype = "to rent"
  }
  const desc_meta_beds = props.data.strapiProperty?.bedroom
  const desc_meta_address = props.data.strapiProperty?.display_address
  const desc_meta_price = `£${new Intl.NumberFormat("en-UK").format(
    props.data.strapiProperty?.price
  )}`

  const pagetitle = `${cap_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}`

  const pagemetadesc = `Know the details of ${desc_meta_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} bedrooms in ${desc_meta_address} at ${desc_meta_price}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to get assistance in finding the right ${desc_meta_ptype} for you.`

  const pageUrl =
    process.env.GATSBY_SITE_URL +
    (props?.location?.pathname).replace(/\/?$/, "/")
  let myimgtransforms = ""
  if (props.data.strapiProperty?.imagetransforms?.images_Transforms) {
    myimgtransforms = JSON.parse(
      props.data.strapiProperty?.imagetransforms?.images_Transforms
    )
  }
  let pageImg = sitelogoimage
  if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
    const mymetaimg = Object.values(myimgtransforms)
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform].webp !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform].webp)
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt[0]
  }

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: props.data.strapiProperty?.slug.replace(/-/g, " "),
    image: props.data.strapiProperty?.images[0]?.url,
    description: pagemetadesc,
    brand: {
      "@type": "Organization",
      name: process.env.GATSBY_SITE_NAME,
      logo: `${process.env.GATSBY_SITE_URL}/images/logo.png`,
    },
    offers: {
      "@type": "Offer",
      url: pageUrl,
      priceCurrency: "GBP",
      price: props.data.strapiProperty?.price,
      availability: "https://schema.org/InStock",
    },
  }
  return (
    <Seo title={pagetitle} description={pagemetadesc}>
      <meta name="image" content={pageImg} />
      <meta name="og:image" content={pageImg} />
      <meta name="og:image:width" content="400" />
      <meta name="og:image:height" content="300" />
      <meta name="twitter:image" content={pageImg} />
      <meta name="og:url" content={pageUrl} />
      <meta name="twitter:url" content={pageUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </Seo>
  )
}

export default PropertyDetails
