import clsx from "clsx"
import React from "react"

const CloseIcon = ({ className, bold, color, ...props }) => {

  return !bold ? (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("close-icon", className)}
      {...props}
    >
      <path
        d="M6 26L26 6M6 6L26 26"
        stroke={"#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("close-icon", className)}
      {...props}
    >
      <path
        d="M2.25 9.75L9.75 2.25M2.25 2.25L9.75 9.75"
        stroke={"#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseIcon
