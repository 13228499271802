import axios from "axios"

const baseURL = `${process.env.GATSBY_STRAPI_SRC}`
const token = process.env.GATSBY_STRAPI_FORM_TOKEN
export const removeSpecialChar = (val, symbol = "-") => {
  if (typeof val === "undefined") {
    return val
      .replace(/[^\w\s]/gi, " ")
      .replace(/\s\s+/g, " ")
      .replace(/ /gi, symbol)
      .toLowerCase()
  }
  return val
}

export const getAvailableDates = (availabilities) => {
  const availableDates = []
  availabilities?.forEach((availability) => {
    const startDate = new Date(availability.StartDate)
    const endDate = new Date(availability.EndDate)

    while (startDate <= endDate) {
      availableDates.push(new Date(startDate))
      startDate.setDate(startDate.getDate() + 1)
    }
  })

  return availableDates
}

export const isDateInArray = (array, value) => {
  return !!array.find((item) => {
    return (
      item.getFullYear() === value.getFullYear() &&
      item.getMonth() === value.getMonth() &&
      item.getDate() === value.getDate()
    )
  })
}

export const dateFormat = (custom_date) => {
  // const today = new Date(custom_date)
  // const yyyy = today.getFullYear()
  // let mm = today.getMonth() + 1 // month is zero-based
  // let dd = today.getDate()

  // if (dd < 10) dd = `0${dd}`
  // if (mm < 10) mm = `0${mm}`

  // const formatted = `${dd} ${mm} ${yyyy}`

  // return formatted

  const date = new Date(Date.parse(custom_date))

  const dateFormatted = date.toLocaleString("default", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })
  return dateFormatted
}

export const numberFormat = (num) => {
  if (!num) return 0
  return new Intl.NumberFormat("en-US", {}).format(num)
}

export function capitalizeWords(arr) {
  return arr.map((word) => {
    const capitalizedFirst = word.charAt(0).toUpperCase()
    const rest = word.slice(1).toLowerCase()
    return capitalizedFirst + rest
  })
}
export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}
export const getBookingPriceDetails = (data, { startDate, endDate }) => {
  const displayData = []
  const { RoomOnlyFinal, AppliedTaxPercentage, Currency } = data.BookingPrice
  console.log(
    "🚀 ~ file: utils.js:94 ~ getBookingPriceDetails ~ data.BookingPrice",
    data
  )

  const taxPrice = data.TouristTaxAmount
  const totalPrice =
    parseInt(RoomOnlyFinal) + (isNaN(taxPrice) ? 0 : parseInt(taxPrice))
  console.log(
    "🚀 ~ file: utils.js:98 ~ getBookingPriceDetails ~ totalPrice",
    totalPrice
  )

  // calculate nights
  const start = new Date(startDate)
  const end = new Date(endDate)
  const nights = (end - start) / (1000 * 60 * 60 * 24)

  const nightText = nights > 1 ? "nights" : "night"

  displayData.push([
    `Price for ${nights} ${nightText}`,
    `${numberFormat(RoomOnlyFinal)} ${Currency}`,
  ])
  displayData.push(["Tax", `${numberFormat(taxPrice)} ${Currency}`])
  displayData.push(["Total", `${numberFormat(totalPrice)} ${Currency}`])

  return { displayData, totalPrice, currency: Currency }
}

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const axiosInstance1 = axios.create({
  baseURL,
  headers: {
    Authorization:
      "Bearer 02de585e315b606014214a15d5d3ed21452517c5de6edafbb459155fe151b85bf31a23db83f5aa961f11780849a11675f676a776b2352a9b573d88a0387d03525186bff7299f73fe8f039d284aa0691f3e6560d6c7f1b078ce7f79fb601c59dca17d30f67a808fe44c3ab49c59f8bdc51f1d58991c8976db6550a0bec5b513e0",
  },
})
