import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import "./ReservationModule.scss"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import CloseIcon from "../Icons/CloseIcon"
// import Payment from "./Payment"

const ReservationModule = ({
  showModal,
  setShowModal,
  propertyData,
  bookingReqData,
  bookingDisplayData,
  paymentData,
}) => {
  const {
    register,
    formState: { errors },
    formState,
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(
      z.object({
        firstName: z.string().min(1),
        lastName: z.string().min(1),
        address: z.string().min(1),
        city: z.string().min(1),
        postcode: z.string().min(1),
        province: z.string().min(1),
        country: z.string().min(1),
        email: z.string().email(),
        phone: z
          .string()
          .regex(/^[\d+]+$/, "Please enter a valid phone number")
          .min(4),
      })
    ),
  })

  const [showPaymentDetails, setShowPaymentDetails] = useState(false)
  const [showStripePayment, setShowStripePayment] = useState(false)

  const [reservationData, setReservationData] = useState(null)

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset()
    }
  }, [formState, reset])

  useEffect(() => {
    if (showModal) {
      setShowPaymentDetails(false)
      setShowStripePayment(false)
      setReservationData(null)
    }
  }, [showModal])

  const onSubmit = data => {
    const { firstName, lastName, email, phone } = data
    const {
      accommodationCode,
      userCode,
      accommodationMasterCode,
      loginGA,
      adultsNumber,
      arrivalDate,
      departureDate,
    } = bookingReqData

    setReservationData({
      accommodationCode,
      userCode,
      accommodationMasterCode,
      loginGA,
      adultsNumber,
      arrivalDate,
      departureDate,
      name: firstName,
      surname: lastName,
      telephone: phone,
      email: email,
      fax: phone,
      dni: "",
      locality: address,
      postCode: postcode,
      city: city,
      country: country,
      isoCountryCode: "AE",
      telephone2: "",
      language: "en",
      documentType: "C",
      paymentMethod: 1,
      bookingType: "PAID",
    })
    // get the ISO 3166-1 alpha-2 country code from the country name

    setShowPaymentDetails(true)
  }

  return (
    <div className="reservation-module-wrap">
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(true)
          // setShowPaymentDetails(false)
        }}
        className="reservation-modal"
        centered
        backdropClassName="reservation-backdrop"
      >
        <Modal.Header className="reservation-model-header">
          <h2>Make a Reservation</h2>
          <CloseIcon onClick={()=> {
            setShowModal(false)
            }} />
        </Modal.Header>
        <Modal.Body className="reservation-modal-body">
          {/* {showStripePayment && reservationData ? (
            <Payment
              reservationData={reservationData}
              paymentData={paymentData}
              propertyData={propertyData}
            />
          ) : showPaymentDetails ? (
            <>
              <div className="payement-details-card">
                <h4 className="title">
                  <span>Payment Details</span>
                </h4>
                <div className="payment-details-wrap">
                  {bookingDisplayData.map((data, i) => (
                    <>
                      {i === bookingDisplayData.length - 1 && (
                        <div className="payment-divider"> </div>
                      )}
                      <div className="payment-detail">
                        <div className="payment-detail-label">{data[0]}</div>
                        <div className="payment-detail-value">{data[1]}</div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <button
                className="submit-button"
                onClick={() => setShowStripePayment(true)}
              >
                Pay
              </button>
            </>
          ) : ( */}
            <div className="reservation-form-wrap">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reservation-form-inputs">
                  <div className="form-input">
                    <label>First Name *</label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("firstName")}
                    />
                    {errors?.firstName && (
                      <p className="error-message">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Surname *</label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("lastName")}
                    />
                    {errors?.lastName && (
                      <p className="error-message">{errors.lastName.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Second Surname</label>
                    <input
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="form-input">
                    <label>Address *</label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("address")}
                    />
                    {errors?.address && (
                      <p className="error-message">{errors.address.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>City *</label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("city")}
                    />
                    {errors?.city && (
                      <p className="error-message">{errors.city.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Postal Code *</label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("postcode")}
                    />
                    {errors?.postcode && (
                      <p className="error-message">{errors.postcode.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Province/County *</label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("province")}
                    />
                    {errors?.province && (
                      <p className="error-message">{errors.province.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Passport/ID Number</label>
                    <input
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="form-input">
                    <label>Country *</label>
                    {/* <input
                      type="text"
                      placeholder=""
                      {...register("country")}
                    /> */}
                    <select {...register("country")}>
                    <option value="27">Afghanistan</option>
   <option value="267">Åland islands</option>
   <option value="28">Albania</option>
   <option value="29">Algeria</option>
   <option value="30">American samoa</option>
   <option value="4">Andorra</option>
   <option value="31">Angola</option>
   <option value="32">Anguilla</option>
   <option value="33">Antarctica</option>
   <option value="34">Antigua and barbuda</option>
   <option value="35">Argentina</option>
   <option value="36">Armenia</option>
   <option value="37">Aruba</option>
   <option value="38">Australia</option>
   <option value="10">Austria</option>
   <option value="39">Azerbaijan</option>
   <option value="40">Bahamas</option>
   <option value="41">Bahrain</option>
   <option value="42">Bangladesh</option>
   <option value="43">Barbados</option>
   <option value="44">Belarus</option>
   <option value="9">Belgium</option>
   <option value="45">Belize</option>
   <option value="46">Benin</option>
   <option value="47">Bermuda</option>
   <option value="48">Bhutan</option>
   <option value="49">Bolivia</option>
   <option value="50">Bosnia and herzegovina</option>
   <option value="51">Botswana</option>
   <option value="52">Bouvet island</option>
   <option value="19">Brazil</option>
   <option value="54">British indian ocean territory</option>
   <option value="55">British virgin islands</option>
   <option value="56">Brunei</option>
   <option value="57">Bulgaria</option>
   <option value="58">Burkina faso</option>
   <option value="59">Burundi</option>
   <option value="60">Cambodia</option>
   <option value="61">Cameroon</option>
   <option value="62">Canada</option>
   <option value="64">Cape verde</option>
   <option value="65">Cayman islands</option>
   <option value="66">Central african republic</option>
   <option value="67">Chad</option>
   <option value="68">Chile</option>
   <option value="69">China</option>
   <option value="70">Christmas island</option>
   <option value="71">Cocos [keeling] islands</option>
   <option value="72">Colombia</option>
   <option value="73">Comoros</option>
   <option value="74">Congo - brazzaville</option>
   <option value="75">Congo - kinshasa</option>
   <option value="76">Cook islands</option>
   <option value="77">Costa rica</option>
   <option value="80">Côte d’ivoire</option>
   <option value="17">Croatia</option>
   <option value="78">Cuba</option>
   <option value="79">Cyprus</option>
   <option value="21">Czech republic</option>
   <option value="22">Denmark</option>
   <option value="81">Djibouti</option>
   <option value="82">Dominica</option>
   <option value="83">Dominican republic</option>
   <option value="86">Ecuador</option>
   <option value="87">Egypt</option>
   <option value="88">El salvador</option>
   <option value="89">Equatorial guinea</option>
   <option value="90">Eritrea</option>
   <option value="91">Estonia</option>
   <option value="92">Ethiopia</option>
   <option value="93">Falkland islands</option>
   <option value="94">Faroe islands</option>
   <option value="95">Fiji</option>
   <option value="96">Finland</option>
   <option value="5">France</option>
   <option value="97">French guiana</option>
   <option value="98">French polynesia</option>
   <option value="101">Gabon</option>
   <option value="102">Gambia</option>
   <option value="103">Georgia</option>
   <option value="6">Germany</option>
   <option value="104">Ghana</option>
   <option value="105">Gibraltar</option>
   <option value="106">Greece</option>
   <option value="107">Greenland</option>
   <option value="108">Grenada</option>
   <option value="109">Guadeloupe</option>
   <option value="110">Guam</option>
   <option value="111">Guatemala</option>
   <option value="112">Guernsey</option>
   <option value="113">Guinea</option>
   <option value="114">Guinea-bissau</option>
   <option value="115">Guyana</option>
   <option value="116">Haiti</option>
   <option value="118">Honduras</option>
   <option value="119">Hong kong</option>
   <option value="120">Hungary</option>
   <option value="121">Iceland</option>
   <option value="122">India</option>
   <option value="123">Indonesia</option>
   <option value="124">Iran</option>
   <option value="125">Iraq</option>
   <option value="126">Ireland</option>
   <option value="127">Isle of man</option>
   <option value="24">Israel</option>
   <option value="8">Italy</option>
   <option value="128">Jamaica</option>
   <option value="129">Japan</option>
   <option value="130">Jersey</option>
   <option value="132">Jordan</option>
   <option value="133">Kazakhstan</option>
   <option value="134">Kenya</option>
   <option value="135">Kiribati</option>
   <option value="136">Kuwait</option>
   <option value="137">Kyrgyzstan</option>
   <option value="138">Laos</option>
   <option value="139">Latvia</option>
   <option value="140">Lebanon</option>
   <option value="141">Lesotho</option>
   <option value="142">Liberia</option>
   <option value="143">Libya</option>
   <option value="144">Liechtenstein</option>
   <option value="145">Lithuania</option>
   <option value="11">Luxembourg</option>
   <option value="146">Macau</option>
   <option value="147">Macedonia</option>
   <option value="148">Madagascar</option>
   <option value="149">Malawi</option>
   <option value="150">Malaysia</option>
   <option value="151">Maldives</option>
   <option value="152">Mali</option>
   <option value="153">Malta</option>
   <option value="154">Marshall islands</option>
   <option value="155">Martinique</option>
   <option value="156">Mauritania</option>
   <option value="3">Mauritius</option>
   <option value="157">Mayotte</option>
   <option value="18">Mexico</option>
   <option value="159">Micronesia</option>
   <option value="161">Moldova</option>
   <option value="162">Monaco</option>
   <option value="163">Mongolia</option>
   <option value="164">Montenegro</option>
   <option value="165">Montserrat</option>
   <option value="12">Morocco</option>
   <option value="166">Mozambique</option>
   <option value="167">Myanmar [burma]</option>
   <option value="168">Namibia</option>
   <option value="169">Nauru</option>
   <option value="170">Nepal</option>
   <option value="7">Netherlands</option>
   <option value="171">Netherlands antilles</option>
   <option value="173">New caledonia</option>
   <option value="174">New zealand</option>
   <option value="175">Nicaragua</option>
   <option value="176">Niger</option>
   <option value="177">Nigeria</option>
   <option value="178">Niue</option>
   <option value="179">Norfolk island</option>
   <option value="180">North korea</option>
   <option value="182">Northern mariana islands</option>
   <option value="183">Norway</option>
   <option value="184">Oman</option>
   <option value="186">Pakistan</option>
   <option value="187">Palau</option>
   <option value="188">Palestinian territories</option>
   <option value="189">Panama</option>
   <option value="191">Papua new guinea</option>
   <option value="192">Paraguay</option>
   <option value="194">Peru</option>
   <option value="195">Philippines</option>
   <option value="196">Pitcairn islands</option>
   <option value="197">Poland</option>
   <option value="2">Portugal</option>
   <option value="198">Puerto rico</option>
   <option value="199">Qatar</option>
   <option value="203">Réunion</option>
   <option value="200">Romania</option>
   <option value="201">Russia</option>
   <option value="202">Rwanda</option>
   <option value="204">Saint barthélemy</option>
   <option value="205">Saint helena</option>
   <option value="206">Saint kitts and nevis</option>
   <option value="207">Saint lucia</option>
   <option value="211">Samoa</option>
   <option value="212">San marino</option>
   <option value="234">São tomé and príncipe</option>
   <option value="213">Saudi arabia</option>
   <option value="214">Senegal</option>
   <option value="215">Serbia</option>
   <option value="217">Seychelles</option>
   <option value="218">Sierra leone</option>
   <option value="219">Singapore</option>
   <option value="208">Sint maarten</option>
   <option value="220">Slovakia</option>
   <option value="221">Slovenia</option>
   <option value="222">Solomon islands</option>
   <option value="223">Somalia</option>
   <option value="224">South africa</option>
   <option value="226">South korea</option>
   <option value="1">Spain</option>
   <option value="227">Sri lanka</option>
   <option value="228">Sudan</option>
   <option value="229">Suriname</option>
   <option value="231">Swaziland</option>
   <option value="232">Sweden</option>
   <option value="20">Switzerland</option>
   <option value="233">Syria</option>
   <option value="235">Taiwan</option>
   <option value="236">Tajikistan</option>
   <option value="238">Thailand</option>
   <option value="239">Timor-leste</option>
   <option value="240">Togo</option>
   <option value="241">Tokelau</option>
   <option value="242">Tonga</option>
   <option value="243">Trinidad and tobago</option>
   <option value="244">Tunisia</option>
   <option value="245">Turkey</option>
   <option value="246">Turkmenistan</option>
   <option value="248">Tuvalu</option>
   <option value="252">Uganda</option>
   <option value="253">Ukraine</option>
   <option value="25">United arab emirates</option>
   <option value="26" selected="">United kingdom</option>
   <option value="237">United republic of tanzania</option>
   <option value="23">United states</option>
   <option value="255">Uruguay</option>
   <option value="256">Uzbekistan</option>
   <option value="257">Vanuatu</option>
   <option value="258">Vatican city</option>
   <option value="259">Venezuela</option>
   <option value="260">Vietnam</option>
   <option value="262">Wallis and futuna</option>
   <option value="263">Western sahara</option>
   <option value="264">Yemen</option>
   <option value="265">Zambia</option>
   <option value="266">Zimbabwe</option>
</select>
                    {errors?.country && (
                      <p className="error-message">{errors.country.message}</p>
                    )}
                  </div>
                  
                  <div className="form-input">
                    <label>Telephone *</label>
                    <input
                      type="number"
                      placeholder=""
                      {...register("phone")}
                    />
                    {errors?.phone && (
                      <p className="error-message">{errors.phone.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Email *</label>
                    <input type="text" placeholder="" {...register("email")} />
                    {errors?.email && (
                      <p className="error-message">{errors.email.message}</p>
                    )}
                  </div>
                  <div className="form-input">
                    <label>Fiscal Code</label>
                    <input
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="form-input">
                    <label>Intra-Community Vat</label>
                    <input
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
                <button type="submit" className="submit-button">
                  Submit
                </button>
              </form>
              <div className="terms-section">
                <p>
                  By clicking Submit, you agree to our{" "}
                  <Link>Terms & Conditions</Link> and{" "}
                  <Link>Privacy Policy</Link>.
                </p>
              </div>
            </div>
          {/* )} */}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ReservationModule
